import { Box, Button, Grid, Stack, Typography, Popover,Link } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState,useRef, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
// import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";
import { useScroll } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import MainSideDrawer from "../../components/mainComponents/mainSideDrawer";
import StartInvesting from "../../components/modals/startInvesting";
import JoinSelect from "../../components/modals/joinSelect";
import { Helmet } from 'react-helmet';
import CpRegistrations from "../../components/modals/cpRegistrations";
import DevelopersRegistraions from "../../components/modals/developersRegistrations";
import { EXTERNAL_URLS } from "../../utils/constants";
import { Polygon } from "@react-google-maps/api";
import{createTheme,colors,ThemeProvider} from '@mui/material';
import Videos from "../../components/modals/videos";
import YouTubeIcon from '@mui/icons-material/YouTube';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from "react-slick";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Zoom } from '@mui/material';
import UseIntersectionObserver from "../../components/useIntersectionObserver";
import ReactGA from 'react-ga4';




// import { makeStyles } from '@mui/material/styles';
// import video from "/public/assets/videos/video1.mp4";
   

export default function LandingPage(){
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        container:document.body,
        offset: ["start", "-50vh"]
    });

    useEffect(()=>{
        // console.log(y)
        window.addEventListener("scroll",autoScroll);
    return ()=> window.removeEventListener("scroll",autoScroll);;
    // eslint-disable-next-line
    },[])

    const autoScroll = (e)=>{
        e.preventDefault();
        let progress = scrollYProgress.get();
        // console.log(scrollYProgress.getVelocity())
        if(progress<1 && progress > 0){
            // window.scrollTo({
            //     top: window.innerHeight,
            //     behavior: 'smooth'
            //   });
        }
    }


    const pageTitle = "RealX - Home Page";
    const pageDescription = "RealX merely provides a platform for legal co-ownership of property assets. Such co-ownership is offered solely for the benefit for financial investment and returns from property investing. Investment opportunities on the platform may carry substantial risk and investors are requested to solicit advice before investing. Information contained in any property deal on the platform, including information regarding targeted returns and investment performance, maybe based on a reasonable growth scenario and is provided by the respective seller. These investments do not carry any government or regulatory protection and Investors must be willing to sustain the risk of loss of capital, including the risk of total loss of capital, despite all the precautions and process put up by us to ensure that does not happen ever.";
    const pageImage = "https://www.realx.in/assets/images/logo.png"; // Placeholder image URL


    return(
        <div>
        <Helmet>
        <title>RealX - Invest in Real Estate</title>
        <meta name="description" content="At RealX, we are obsessed with bringing quality investment opportunities to everyone. We have broken the access barrier and brought this down, so everyone can digitally invest in quality Real Estate." />
        <meta property="og:title" content="Invest in Real Estate" />
        <meta property="og:description" content="Invest in Real Estate" />
        <meta property="og:image" content="https://www.realx.in/assets/images/logo.svg" />
        <meta property="og:url" content="https://www.realx.in" />
      </Helmet>
      
        <Box sx={{bgcolor:"#FFF"}}>

                     
            <div>
                <Helmet>
                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content={pageTitle} />
                    <meta property="og:description" content={pageDescription} />
                    <meta property="og:image" content={pageImage} />
                    <meta property="og:url" content="https://www.example.com/my-page" />
                    <meta property="og:type" content="website" />

                    {/* Other meta tags */}
                    <meta name="description" content={pageDescription} />
                    <meta name="keywords" content="react, helmet, meta tags" />

                    {/* Title tag */}
                    <title>{pageTitle}</title> 
                </Helmet>
            </div>


            <HeroSection />
                <Box
                    ref={ref}
                    sx={{
                        width:"100vw",
                        left:"50%",
                        right:"50%",
                        marginX:"-50vw",
                        position:"relative",
                        zIndex:1,
                        background:"transparent linear-gradient(0deg, #FFFFFF 0%, #D6E1EA 51%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
                        boxShadow:"0px -5px 6px #0000000A"
                    }}
                >
                    <Box
                        sx={{
                            maxWidth:"1920px",
                            margin:"auto"
                        }}
                    >
                        {/* <FirstSection />
                        <SecondSection />
                        <ThirdSection /> */}
                        {/* <SelectSection /> */}
                        <VideosSection />
                        <RealxInNews />    
                        <PartnersLogo/>
                        
                      
                    </Box>
                </Box>
            {/* <ForthSection />
            <FifthSection />
            <SixthSection /> */}
            {/* <WhySection /> */}
            {/* <ContactUsSection /> */}
            {/* <Footer /> */}
            <LogSignUpButton />

        </Box>
      </div>
    )
}

const HeroSection = () =>{
    const navigate = useNavigate();
    const [showLogin,setShowLogin] = useState(false);
    const [showSignUp,setShowSignUp] = useState(false);
    const [openInvite,setOpenInvite] = useState(false);
   
    const [cpshowLogin,setCpSignup] = useState(false);
    const [cpshowSignUp,setShowCpSignup] = useState(false);
    const [cpopenInvite,setCpSignupForm] = useState(false);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [showTerms,setShowTerms] = useState(false);

    const [devshowLogin,setDevSignup] = useState(false);
    const [devshowSignUp,setShowDevSignup] = useState(false);
    const [devopenInvite,setDevSignupForm] = useState(false);
    

          // Define submenu options
    const ExploresubmenuOptions = [
        {
            name: "How It works",

            onClick: () => navigate("/how-it-works")

        },
        {
            name: "Why RealX",
            onClick: () => navigate("/why-realx")
        },
        {
            name: "About Us",
            onClick: () => navigate("/about-us")

        },
     

    ]
  const submenuOptions = [
      {
        name: "Signup As Channel Partner",
       onClick: ()=>setCpSignupForm(true)
  
      },
    {
      name: "Signup As Developer",
     onClick: ()=>setDevSignupForm(true)
    },
  ];

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const[exploresubmenuAnchorEl,setexploreSubmenuAnchorEl]= useState(null);

  const handleSubmenuClick = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };
  const exploreSubmenuClick=(event)=>{
    setexploreSubmenuAnchorEl(event.currentTarget)
}

  const exploreSubmenuClose = () => {
    setexploreSubmenuAnchorEl(null);
  };

  const handleLoginSignupClick = () => {
    // Log the event to Google Analytics
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked on Login/Signup',
      label: 'Login/Signup Button'
    });

    // Open the link in a new tab
    window.open(EXTERNAL_URLS.platformLink, '_blank');
  };

    return(    
        <Box sx={{
            width:"100%",
            height:"100vh",
            maxWidth:"1920px",
            position:"relative"
        }}>
            <Helmet>
                <title>RealX - Home Page</title>
                <meta name="description" content="Unlock premium real estate investment opportunities with RealX. Our obsession is to democratize access, making quality investments in real estate available to all. Say goodbye to barriers and embrace digital investment in the finest properties." />
                <meta name="keywords" content="RealEstateInvestments, DemocratizeAccess, InvestmentOpportunities"></meta>
            </Helmet>

            <MainSideDrawer open={openDrawer} handleClose={()=>setOpenDrawer(false)} />
            <StartInvesting setShowLogin={()=>{setShowLogin(true); setShowSignUp(true)}} open={openInvite} handleClose={()=>setOpenInvite(false)} />

            <CpRegistrations setShowLogin={()=>{setCpSignup(true); setShowCpSignup(true)}} open={cpopenInvite} handleClose={()=>setCpSignupForm(false)}></CpRegistrations>
            <DevelopersRegistraions setShowLogin={()=>{setDevSignup(true); setShowDevSignup(true)}} open={devopenInvite} handleClose={()=>setDevSignupForm(false)}></DevelopersRegistraions>
            
            <Box
                sx={{
                    minWidth:"100vw",
                    minHeight:"100vh",
                    maxWidth:"inherit",
                    background:"transparent linear-gradient(9deg, #D1DEE8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
                    // position:"fixed",
                    // left:"50%",
                    // right:"50%",
                    // marginX:"-50vw",
                }}
            >
                <Box component={"img"} src={"/assets/images/landing/buildings.svg"} sx={{
                                position:"fixed",
                                bottom:0,
                                left:"50%",
                                width:{md:"50%",xs:"100%"},
                                transform:"translateX(-50%)"
                            }} />
                <Box
                    sx={{
                        position:"relative",
                        px:{xl:15,lg:15,md:7,xs:3},
                        display:"flex",
                        maxWidth:"1920px",
                        minHeight:{xs:"90vh",md:"100vh"},
                        alignItems:"center",
                        margin:"auto"
                    }}
                >
                    <Box sx={{
                        position:"absolute",
                        top:{md:20,xs:24},
                        width:"100%",
                        left:0,
                        px:{xl:15,lg:15,md:7,xs:3},
    
                    }}>
                        <Stack direction={"row"} justifyContent={"space-between"}  sx={{mr:{md:"110px",lg:"64px"}}}>
                            <Stack direction={"row"} spacing={"90px"} flex={1} alignItems={"center"}>
                                <Box onClick={()=>navigate("/")} sx={{height:{md:"5vw",xs:"7vw"},cursor:"pointer",minWidth:{md:"155px",xs:"60px"}}} component={"img"} src={"/assets/images/logo.svg"}></Box>
                            </Stack>
                            <Stack sx={{display:{xs:"none",md:"flex",height:"fit-content"},mr:"22px"}} alignItems={"center"} direction={"row"} spacing={{ xs: "10px", sm: "15px", md: "15px", lg: "30px" }}>
                             
                              {/* <Typography onClick={()=>navigate("/how-it-works")} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>How it works</Typography> */}
                                {/* <Typography onClick={()=>navigate("/why-realx")} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Why RealX</Typography> */}
                                <Typography onClick={()=>navigate("/faq")} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>FAQs</Typography>
                                <Typography  onClick={exploreSubmenuClick} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Explore RealX</Typography>
                                {/* <Typography onClick={()=>navigate("/about-us")} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>About Us</Typography> */}
                                <Typography onClick={handleSubmenuClick} variant="body1" sx={{ fontWeight: 600, cursor: "pointer" }}>Associate With Us</Typography>
                                {/* Submenu Popover */}
                                <Popover
                                    open={Boolean(submenuAnchorEl)}
                                    anchorEl={submenuAnchorEl}
                                    onClose={handleSubmenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    disableScrollLock // Prevents the page from shifting
                                >
                                    <Box p={2}>
                                        {submenuOptions.map((option, index) => (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight:"Normal"}}
                                                onClick={() => {
                                                    handleSubmenuClose();
                                                    option.onClick(); // Execute the dynamic function
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Popover>
                                <Popover
                                open={Boolean(exploresubmenuAnchorEl)}
                                    anchorEl={exploresubmenuAnchorEl}
                                    onClose={exploreSubmenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    disableScrollLock // Prevents the page from shifting
                                >
                                    <Box p={2}>
                                        {ExploresubmenuOptions.map((option, index) => (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                                onClick={() => {
                                                    exploreSubmenuClose();
                                                    option.onClick(); // Execute the dynamic function
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        ))}
                                    </Box>

                                </Popover>
                               
                                <Button onClick={handleLoginSignupClick} variant="contained"  sx={{marginRight:{md:"5px"}}}>
                                    <Typography    sx={{fontWeight:600,cursor:"pointer",lineHeight:1, paddingLetf: "20px"}}>Login / Signup</Typography>
                                </Button>
                
                                {/* <Button onClick={()=>setShowLogin(true)} variant="contained" sx={{bgcolor:"#252525"}}>
                                    <Typography  variant="body1" sx={{fontWeight:600,cursor:"pointer",lineHeight:1}}>Sign Up</Typography>
                                </Button> */}
                                
                            </Stack>
                            

                            {/* <Stack direction={"row"} alignItems={"start"}>
                            <Button onClick={() => window.open(EXTERNAL_URLS.platformLink, '_blank')} variant="outlined" sx={{color:"#252525",borderColor:"#252525",padding:{xs:"8px 16px",md:"12px 24px"},marginLeft:{xs:"20px",sm:"20px",md:"20px",lg:"40px"}}}>
                                    <Typography  variant="body1" sx={{fontWeight:600,cursor:"pointer",lineHeight:1 }}>Login / Signup</Typography>
                            </Button>

                            </Stack> */}
            
                            <MenuIcon onClick={()=>setOpenDrawer(true)} sx={{cursor:"pointer",display:{md:"none",xs:"block"},position:"absolute",right:{xs:"25px"}}} fontSize="medium" />
                        </Stack>
                    </Box>
                    <Grid container justifyContent={"space-between"} direction={{xs:"column-reverse",md:"row"}} rowGap={4}>
                        <Grid item xl={6} lg={6} md={6} xs={12} >
                            <Stack spacing={{xl:2,lg:2,md:2,xs:2}} justifyContent={"flex-end"} sx={{height:"100%"}}>
                                <Stack spacing={{xl:2,lg:2,md:2,xs:2}}>
                                    <Box>
                                        <Typography sx={{fontSize:{xl:65,lg:45,md:40,xs:36},fontWeight:600,fontFamily:"Teko",lineHeight:{md:0.9,xs:1}}}>
                                            INVEST IN REAL ESTATE<br />
                                        <Box sx={{color:"primary.main"}}>START AS LOW AS INR 5000</Box>
                                        </Typography>
                                    </Box>
                                    <Typography variant="landingSubText">
                                        At RealX, we are obsessed with bringing quality investment opportunities to everyone. We have broken the access barrier and brought this down, so everyone can digitally invest in quality Real Estate.
                                    </Typography>               
                                </Stack>
                                <Stack direction={"row"} pt={{xl:1,lg:1,xs:0}} spacing={{md:3}}>
                                    <Button   onClick={()=>window.open(EXTERNAL_URLS.marketPlaceLink, '_blank')}  variant="contained" sx={{ padding:"12px 24px" ,fontSize:{xl:"20px",lg:"15px"},width:"fit-content",lineHeight:1,display:{xs:"none",md:"block"}}}> Start Investing</Button>   
                                    <Button
                                        onClick={() => window.open(EXTERNAL_URLS.marketPlaceLink, '_blank')}
                                        sx={{
                                            border: '1px solid #2F6BF2',
                                            padding: "12px",
                                            fontSize: '14px !important',
                                            width: "fit-content",
                                            lineHeight: 1,
                                            fontWeight:400,
                                            display: { xs: "block", md: "none" }
                                        }}>
                                        Start Investing
                                    </Button>           
                                    {/* <Button onClick={()=>navigate('/ayodhya')} variant="ayodhya" sx={{ marginLeft:'0px',padding:"12px 24px" ,fontSize:{xl:"20px",lg:"15px"},width:"fit-content",lineHeight:1}}>Pre-register for Ayodhya Land Deal</Button>                 */}
                                    
                                    {/* <IsPossibleNow /> */}   
                                    {/* <Button variant="outlined" sx={{padding:{lg:"16px 32px"},fontSize:{xl:"24px",lg:"18px"},width:"fit-content"}}>How to invest</Button> */}
                                </Stack>
                                <IsPossibleNow />
                                {/* <Stack mt={5}>
                                    <Typography sx={{fontSize:{md:"21px !important",xs:"18px !important"},color:"#949494",fontStyle:"italic"}}>Our Partners:</Typography>
                                    <Stack mt={1.5} direction={"row"} columnGap={3} rowGap={1} flexWrap={"wrap"}>
                                        <Box
                                            // component={'img'}
                                            // src={`a`}
                                            sx={{
                                                width:{md:124,xs:80},
                                                height:24,
                                                bgcolor:"#949494",
                                                mask:`url('/assets/images/landing/partners/dropbox.svg')  no-repeat center / contain`
                                            }}
                                        >
                                        </Box>
                                        <Box
                                            // component={'img'}
                                            // src={`a`}
                                            sx={{
                                                width:{md:124,xs:80},
                                                height:24,
                                                bgcolor:"#949494",
                                                mask:`url('/assets/images/landing/partners/dropbox.svg')  no-repeat center / contain`
                                            }}
                                        >
                                        </Box>
                                        <Box
                                            // component={'img'}
                                            // src={`a`}
                                            sx={{
                                                width:{md:124,xs:80},
                                                height:24,
                                                bgcolor:"#949494",
                                                mask:`url('/assets/images/landing/partners/dropbox.svg')  no-repeat center / contain`
                                            }}
                                        >
                                        </Box>
                                    </Stack>
                                </Stack> */}
                            </Stack>
                        </Grid>
                        <Grid item md={5.6} xs={12} sx={{
                            pt:{md:"0",xs:"67%"},
                            position:"relative"
                        }}>
                            <Box 
                                sx={{
                                    // bgcolor:"",
                                    width:"100%",
                                    pt:"67%",
                                    position:{md:"relative",xs:"initial"}
                                }}
                            >
                                <Stack sx={{position:"absolute",top:0}} direction={"row"} height={"100%"} spacing={1}>
                                    <Box sx={{
                                        height:"95%",
                                        bgcolor:"black",
                                        alignSelf:"flex-end",
                                        mr:0.5,
                                        flex:1
                                    }}>
                                        <Box sx={{width:"100%",height:"100%"}} component={"img"} src={"/assets/images/fracs/1.png"}></Box>
                                    </Box>
                                    <Box sx={{
                                        height:"95%",
                                        bgcolor:"black",
                                        flex:1
                                    }}>
                                        <Box sx={{width:"100%",height:"100%"}} component={"img"} src={"/assets/images/fracs/2.png"}></Box>
                                    </Box>
                                    <Box sx={{
                                        height:"95%",
                                        bgcolor:"black",
                                        alignSelf:"flex-end",
                                        flex:1
                                    }}>
                                        <Box sx={{width:"100%",height:"100%"}} component={"img"} src={"/assets/images/fracs/3.png"}></Box>
                                    </Box>
                                    <Box sx={{
                                        height:"95%",
                                        bgcolor:"black",
                                        flex:1
                                    }}>
                                        <Box sx={{width:"100%",height:"100%"}} component={"img"} src={"/assets/images/fracs/4.png"}></Box>
                                    </Box>
                                    <Box sx={{
                                        height:"95%",
                                        bgcolor:"black",
                                        alignSelf:"flex-end",
                                        ml:"12px !important",
                                        flex:1,
                                    }}>
                                        <Box sx={{width:"100%",height:"100%"}} component={"img"} src={"/assets/images/fracs/5.png"}></Box>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Stack direction={"column"} alignItems={"center"}
                    sx={{
                        position:"absolute",
                        bottom:20,
                        left:"50%",
                        transform:"translateX(-50%)",
                        animation:"bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)",
                        "@keyframes bounce": {
                            "50%" :{
                                transform: "translateX(-50%) translateY(-15px)"
                                }
                        },
                        display:{md:"flex",xs:"none"}
                    }}>
                        {/* <Typography sx={{fontWeight:500,fontSize:25,lineHeight:1}}>Scroll down</Typography>
                        <ExpandMoreIcon /> */}
                    </Stack>
                </Box>
            </Box>

        </Box>
    )
}



// ----Website Revamp Code start-----
const LogSignUpButton = ()=>{
    return(
        <Box sx={{display:{md:"none"},position:{xs:"fixed"},bottom:{xs:"5px"},left:{xs:"20px"},zIndex:"999"}}>
            <Button  onClick={() => window.open(EXTERNAL_URLS.platformLink, '_blank')} variant="contained" sx={{ ml:"4px",padding:'12px'}}>
                <Typography variant="body1" sx={{ fontWeight: 600, cursor: "pointer", lineHeight: 1 }}>Login / Signup</Typography>
            </Button>            
        </Box>
   
        
    )
    
}



const SelectSection = () => {
   
    const theme = useTheme();
    const isaboveMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [openSelect, setOpenSelect] = useState(false);

    const advantage=[
        {adv : "Access to exclusive deals"},
        {adv : "Early access to many properties"},
        {adv : "10% Off on realX fees"}
    ]

    return (
        <Box sx={{
            pt: { lg: "100px", md: "75px", xs: 3 },
            pb: { lg: "75px", md: "75px", xs: 3 },
            px: { xl: 15, lg: 15, md: 7, xs: 3 },
            bgcolor: "#fff",
            minHeight: { lg: "80vh"},
            width: '100%',
            '@media (min-width:900px)': {
                backgroundImage: 'none',
              },
            // width: '100%',
            objectFit: "cover",
            backgroundImage: `url("/assets/images/landing/bgselect.png")`,
            objectFit: "cover",
            backgroundPosition: "center",
            height:"100%"    
            }} >
         <Box>  
            <JoinSelect open={openSelect} handleClose={()=>setOpenSelect(false)} />
            <Grid container direction={{  md: "row" }}  sx={{alignItems:{md:"center"},justifyContent:{xs:'center',md:'none'}}} rowGap={0}>
               <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                    {/* <Box sx={{ position: "relative", overflow: "hidden" }}></Box> */}
                    {isaboveMediumScreen &&  <Box sx={{ width: "100%", minHeight: { md: "70vh",lg:"80vh", xs: "25vh" }, objectFit: "cover",borderTopLeftRadius:'7%',borderBottomLeftRadius:'7%', clipPath:{md:"Polygon(0 0, 100% 0, 80% 100%, 0% 100%)"} }} component={"img"} src={"/assets/images/landing/section1.webp"}></Box>}
                </Grid>
                 <Grid item xl={6} lg={6} md={6} xs={12} sx={{ position: { md: "relative" }, zIndex: { md: "1" } }}>
                    <Stack spacing={{ xl: 5, lg: 5, md: 5, xs: 3}}>
                        <Stack direction={"row"}   justifyContent={"center"} >
                            <Box sx={{ height:{md: "7vw", xs: "10vh"}, cursor: "pointer" }} component={"img"} src={"/assets/images/landing/section11.webp"}></Box>
                        </Stack>
                        <Stack spacing={{ xl: 2, lg: 2, md: 2,sm:2, xs: 1.5}}  alignItems={"center"}>
                           
                                <Typography sx={{color:"#353935",fontWeight: 600,  fontSize: { xl: "60px", lg: 45, md: 40, xs: 30 }, fontFamily: "Teko", lineHeight: {xs: 1 },textAlign:{xs:"center"} }}>
                                    Get access to  <span style={{ color: "red" }}>Premium</span> properties
                                </Typography>                 
                        </Stack>
                    </Stack>    
                    <Stack sx={{alignItems:'center'}} spacing={{ xl: 7, lg: 7, md: 5, xs: 3}} mt={{lg:3,md:2,xs:1}}>
                        <Stack>
                            {advantage.map((row, i) => (
                                <Stack direction={'row'} >
                                    <ExpandCircleDownRoundedIcon sx={{ transform: 'rotate(270deg)',color: 'red',fontSize: { xs: '12px', md: '15px',lg:'20px',xl:'30px' },mt:{lg:'3%',xs:'3%'}}} />
                                    <Typography  sx={{color:"#353935",fontWeight: 400, fontSize: { xl: "43px", lg: '28px', md: "23px", xs: "17px" },ml:{md:'10px',xs:'5px'}, fontFamily: 'teko' }}>{row.adv}</Typography>
                                </Stack>
                            ))}
                        </Stack>

                           <Button variant="contained" sx={{fontSize:{xl:"24px",lg:"18px",md:"15px",xs:"15px"},width:'60%'}}  onClick={() => setOpenSelect(true)}>Join Select</Button>
                         
                            {/* <Button variant="outlined" sx={{padding:{lg:"16px 32px"},fontSize:{xl:"24px",lg:"18px"},width:"fit-content"}}>How to invest</Button> */}
                       
                    </Stack>
                    </Grid>
                </Grid>
                 </Box>
            </Box>
       
            )
}



const VideosSection = () => {

    const theme = useTheme();
    const isMobileQuery = useMediaQuery(theme.breakpoints.down('md'));

    const [openVideo, setOpenVideo] = useState(false);
    const [modelData,setmodelData] = useState({ url: '' })

      const sliderSettings = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive :[
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                   dots: true
                }
              },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                   dots: true
                }
              },
        ]
    };

    const openModel = (url) => {
            setmodelData(url);
            setOpenVideo(true)
    }
      
    const videos = [
        {
            videoTitle: "What is realX",
            imgPath: "/assets/images/landing/whatrealximg.webp",
            videoUrl: "fQtFJw3qqxs"
        },
        {
            videoTitle: "How to buy property on realX",
            imgPath: "/assets/images/landing/thumbnail2.webp",
            videoUrl: "cjb38YufZJE"
        },
        {
            videoTitle: "Investing through property tokens",
            imgPath: "/assets/images/landing/tokens.webp",
            videoUrl: "aG2NRf-MYEM"
        }
    ]


    return (
        <>
            <Box
                sx={{
                    px: { xl: 15, lg: 15, md: 7,sm:5, xs: 3 },
                    py: { lg: '50px', md: "50px", xs: 4 },
                    bgcolor: "#fff",
                    }}
            >   
                <Stack>
                    <Typography sx={{ color: "#353935", zIndex: 2, position: "relative", textAlign: "center", fontSize: { xl: 65, lg: 45, md: 40, xs: 30 } }} variant="landingTitle">RealX Guide</Typography>
                </Stack>
                <Videos open={openVideo} data={modelData}  handleClose={()=>setOpenVideo(false)} />

                {isMobileQuery ? (
                    <Slider {...sliderSettings}>
                        {videos.map((v, i) => {
                            // Use the hook unconditionally for each video
                            const [ref, isIntersecting] = UseIntersectionObserver({
                                threshold: 0.2,
                            });

                            return (
                                <Box key={v.videoUrl} sx={{padding:'16px 8px 0px'}}>
                                    <Stack
                                        sx={{
                                            position: "relative",
                                            direction: "row",
                                            justifyContent: "center",

                                        }}>
                                        <Typography sx={{ textAlign: "center", mb: "5px", zIndex: 0, fontWeight: 500, position: "relative", fontSize: { xs: "1.3rem", sm: "1.8rem", md: "1.2rem", lg: "1.45rem" }, color: "#353935" }} variant="landingTitle">{v.videoTitle}</Typography>
                                    </Stack>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            maxWidth: "100%",
                                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                            backdropFilter: 'blur(10px)',
                                            boxShadow: '5px 5px 5px rgba(30,30,30,0.3)',
                                            border: '3px solid rgba(128, 128, 128, 0.1)',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <img
                                            // className="imgVideo"

                                            style={{
                                                width: "100%",
                                                height: { md: '200px', xs: "180px", sm: "225px" },
                                                objectFit: "cover",
                                                cursor: 'pointer',
                                                borderRadius: '5px'


                                            }}
                                            onClick={() => openModel(v.videoUrl)}
                                            src={v.imgPath}
                                            alt="image"
                                        />
                                        <Box
                                            sx={{
                                                padding: '11px',
                                                backgroundColor: "white",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}
                                        ></Box>
                                        <YouTubeIcon
                                            className="iconYoutube"
                                            sx={{
                                                fontSize: { lg: "60px", md: "50px", sm: "75px", xs: "55px" },
                                                color: "red",
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                "&:hover": { color: "#f44336" },
                                            }}
                                            onClick={() => openModel(v.videoUrl)}
                                        />
                                    </Box>

                                </Box>
                            );
                        })}
                    </Slider>

                ) : (
                    <Box  sx={{py: { lg: '50px', md: "30px", xs: 2 },}}>
                    <Zoom in={true} timeout={500}>
                        <Grid container gap={7} rowGap={3} justifyContent={"center"}>

                            {
                                videos.map((v, i) => {
                                    const [ref, isIntersecting] = UseIntersectionObserver({
                                        threshold: 0.2, // Trigger animation when 20% of the element is in view
                                    })
                                    return (
                                        <Grid key={v.videoUrl} item xs={11} sm={8} md={3.3} lg={3.5} justifyContent={"center"} ref={ref}

                                            style={{
                                                opacity: isIntersecting ? 1 : 0,
                                                transform: isIntersecting ? 'translateY(0)' : 'translateY(100px)',
                                                transition: 'opacity 1s ease-out, transform 1s ease-out'
                                            }}>
                                            <Stack
                                                sx={{
                                                    position: "relative",
                                                    direction: "row",
                                                    justifyContent: "center",

                                                }}>
                                                <Typography sx={{ textAlign: "center", mb: "5px", zIndex: 0, fontWeight: 500, position: "relative", fontSize: { xs: "1.3rem", sm: "1.8rem", md: "1.2rem", lg: "1.45rem" }, color: "#353935" }} variant="landingTitle">{v.videoTitle}</Typography>
                                            </Stack>


                                            <Box sx={{
                                                position: "relative",
                                                maxWidth: "100%",
                                                backgroundColor: 'rgba(255, 255, 255, 0.15)', // Semi-transparent background
                                                backdropFilter: 'blur(10px)', // Blur effect
                                                boxShadow: '5px 5px 5px rgba(30,30,30,0.3)',
                                                border: '3px solid rgba(128, 128, 128, 0.1)',
                                                borderRadius: '5px'

                                            }}>
                                                <img
                                                    className="imgVideo"

                                                    style={{
                                                        width: "100%",
                                                        height: { md: '200px', xs: "180px", sm: "225px" },
                                                        objectFit: "cover",
                                                        cursor: 'pointer',
                                                        borderRadius: '5px'


                                                    }}
                                                    onClick={() => openModel(v.videoUrl)}
                                                    src={v.imgPath}
                                                    alt=""
                                                />
                                                <Box sx={{
                                                    padding: { xs: "8px", sm: "11px", md: "8px", lg: '11px' },
                                                    backgroundColor: "white",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}></Box>
                                                <YouTubeIcon
                                                    className="iconYoutube"
                                                    sx={{
                                                        fontSize: { lg: "60px", md: "50px", sm: "75px", xs: "55px" },
                                                        color: "red",
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        "&:hover": { color: "#f44336" },
                                                    }}
                                                    onClick={() => openModel(v.videoUrl)}
                                                />
                                            </Box>

                                        </Grid>
                                    )
                                })
                            }

                        </Grid>
                    </Zoom>
                </Box>
                )}
            </Box>
        </>
    )

}


const RealxInNews = () => {
    
        const slider = useRef(); 
        
        const next = () => {
         slider.current.slickNext();
        };
        const previous = () => {
         slider.current.slickPrev();
        }; 

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      variableWidth: true,
      arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                 dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                
              }
            }
          ]

        };
      
    return (
        <Box sx= {{ py: { lg: "50px", md: "50px", xs: 3}, width: '100%',position:'relative', background:"transparent linear-gradient(1deg, #D6E1EA 10%, #FFFFFF 50%, #D6E1EA 100%) 0% 0% no-repeat padding-box",}}>
        <Stack >
        <Typography sx={{ color:"#353935",zIndex: 2, position: "relative" ,textAlign:"center",fontSize:{xl:65,lg:45,md:40,xs:30}}} variant="landingTitle">RealX In News</Typography>
        </Stack>
         <Button onClick={previous} sx={{top:'50%',position:'absolute',left:{xs:0,lg:40}}}> 
           <NavigateBeforeIcon sx={{mr:'13px',color:'black',bgcolor:'whitesmoke',borderRadius:1,fontSize:{xs:'25px',md:'35px'}, "&:hover": { color: "#f44336" }}} /> 
         </Button> 
         <Button onClick={next} sx={{top:'50%',position:'absolute',right:{xs:0,lg:40}}}>
         <NavigateNextIcon sx={{ml:'13px',color:'black',bgcolor:'whitesmoke',borderRadius:1,fontSize:{xs:'25px',md:'35px'}, "&:hover": { color: "#f44336" }}} /> 
         </Button>
         <Box sx={{   
              py: { lg: "50px", md: "30px", xs: 3},
              px: { xl: 22, lg: 20, md: 10, xs: 7},
 
         }}>
           <Slider 
            ref={(c) => (slider.current = c)} 
            {...settings }
            style={{objectFit:'contain'}}
            > 

                <Link href="https://www.linkedin.com/posts/bankingfrontiers_real-estate-tokenization-manish-kumar-realx-activity-7113386739557167104-PyjQ?utm_source=share&utm_medium=member_android" target="_blank">
                    <Box className="newlogohover" sx={{height:{xs:'75px',md:'100px'},mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap5.png"}></Box>
                </Link>
                
           
                <Link href="https://www.worldwealthjournal.com/article/649775246-realx-creates-breakthrough-in-digital-assets-space-issues-property-tokens-with-direct-legal-rights-on-property" target="_blank">
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap10.png"} ></Box>
                </Link>

                <Link href="https://www.google.com/url?sa=D&q=https://theasianchronicle.com/press-release/%3Fc_article_id%3D25282%26c_author_id%3D20298%26originurl%3Dhttps%253A%252F%252Ftheasianchronicle.com%252Fpress-release%252F&ust=1693402920000000&usg=AOvVaw2FmcO4rJSSNKAYPCiVEN4j&hl=en&source=gmail" target="_blank" >
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px'},mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap9.png"}></Box>
                </Link>

                <Link href="https://www.financialexpress.com/business/industry-realx-announces-first-commercial-transaction-with-issue-of-digital-property-tokens-3205628/" target="_blank">
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap2.png"}></Box>
                </Link>
                 

                <Link href="https://www.zeebiz.com/companies/news-realx-achieves-remarkable-milestone-in-real-world-digital-assets-248446" target="_blank">
                    <Box className="newlogohover" sx={{ width:'100px',height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap3.png" }></Box>
                </Link>


                <Link href="https://www.aninews.in/news/business/business/realx-achieves-a-milestone-achievement-in-real-world-digital-assets-space20230810104205/#:~:text=Mumbai%20(Maharashtra)%20%5BIndia%5D,due%20registration%20process%20of%20a" target="_blank">
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap7.png"}></Box>
                </Link>
            
                <Link href="https://m.dailyhunt.in/news/india/english/newsvoir-epaper-newsvoir/realx+achieves+a+milestone+achievement+in+real+world+digital+assets+space-newsid-n526571388?listname=newspaperLanding&topic=business&index=2&topicIndex=0&mode=pwa&action=click" target="_blank" >
                    <Box className="newlogohover" sx={{height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap4.png"}></Box>
                </Link>
              
                <Link  href="https://www.theweek.in/wire-updates/business/2023/08/10/dcm6-realx.html" target="_blank">
                    <Box className="newlogohover" sx={{ width:'150px',height:{xs:'75px',md:'100px'},mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap6.png" }></Box>
                </Link>
           

                <Link href="https://www.lokmattimes.com/business/realx-achieves-a-milestone-achievement-in-real-world-digital-assets-space/" target="_blank">
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px' },mx:{md:'30px',xs:'20px'}}} component={"img"} src={"/assets/images/mediapartner/mediap12.png" }></Box>
                </Link>  
              
                <Link href="https://bfsi.economictimes.indiatimes.com/news/fintech/realx-launches-blockchain-based-property-tokens-completes-first-global-commercial-transaction/102575140"target="_blank" >
                    <Box className="newlogohover" sx={{width:{md:'200px',xs:'100'},mx:{md:'40px',xs:'30px'},height:{md:"30px",xs:'15px'},mt:{xs:'13%',sm:'13%',md:'11%',lg:'14%'}}} component={"img"} src={"/assets/images/mediapartner/mediap15.png"}></Box>
                </Link>
           
                    
                <Link href="https://www.business-standard.com/content/press-releases-ani/realx-achieves-a-milestone-achievement-in-real-world-digital-assets-space-123081000216_1.html" target="_blank" >
                    <Box className="newlogohover" sx={{ height:{xs:'75px',md:'100px' },mx:{md:'40px',xs:'30px'}}} component={"img"} src={"/assets/images/mediapartner/mediap8.png"}></Box>
                </Link>

           </Slider> 
         </Box>

         </Box>
         
    ) 
        
}


  const PartnersLogo = () => {

    const settings = {
        dots: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:false
                }
              },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                 dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                
              }
            }
          ]

        };
    
    
    
    return(

      <Box sx={{
            py: { lg: "50px", md: "50px", xs: 3 },
            px: { xl: 20, lg: 18, md: 8, xs: 5 },
            width: '100%', position: 'relative',
            bgcolor: "white",
            // background:"transparent linear-gradient(1deg, #D6E1EA 0%, #FFFFFF 50%, #D6E1EA 100%) 0% 0% no-repeat padding-box",
         
        }}>
            <Stack direction="column">

                <Stack >
                    <Typography sx={{ color: "#353935", zIndex: 2, position: "relative", textAlign: "center", fontSize: { xl: 65, lg: 45, md: 40, xs: 30 } }} variant="landingTitle">Our Partners</Typography>
                </Stack>
                <Box className="partners-slider" sx={{py: { lg: "50px", md: "30px", xs: 2 }}}>
                    <Slider  {...settings}>
                        <Box sx={{ bgcolor: "black", padding: 1,borderRadius:1}} component={"img"} src={"/assets/images/partnersLogo/seracle_logo.webp"}></Box>
                        <Box  component={"img"} src={"/assets/images/partnersLogo/emudhra.webp"}></Box>
                        <Box  component={"img"} src={"/assets/images/partnersLogo/algorand_black.webp"}></Box>
                        <Box  component={"img"} src={"/assets/images/partnersLogo/iref-logo.webp"}></Box>
                        <Box  component={"img"}  src={"/assets/images/partnersLogo/fundbezzie.webp"}></Box>
                        <Box  component={"img"} src={"/assets/images/partnersLogo/amicorp.webp"}></Box>
                    </Slider>
                </Box>
            </Stack>
        </Box>
   
  );
}
  
  


// ----Website Revamp Code Ends -----



//----old website code------
const FirstSection = () =>{
    return(
        <Box
            sx={{
                py:{lg:"100px",md:"150px",xs:5},
                px:{lg:0,xs:3,md:8},
                pb:{xs:10}
            }}
        >
            <Helmet>
                <meta name="description" content="RealX unlocks premium real estate investments for all. Democratizing access, we offer quality opportunities in the finest properties. Embrace digital investment now" />
                <meta name="keywords" content="RealEstateInvestments, DemocratizeAccess, DigitalInvestment"></meta>
            </Helmet>

            <Box
                sx={{
                    position:"relative",
                    mx:"auto",
                    width:{lg:"70%",xs:"100%"},
                    pt:{lg:"33%",xs:"70%"}
                }}
            >
                <Box 
                    sx={{
                        position:"absolute",
                        background:"url('/assets/images/landing/placeholder1.png') no-repeat",
                        width:{md:"85%",xs:"100%"},
                        pt:{md:"40%",xs:"70%"},
                        top:0,
                        left:0,
                        backgroundSize:"cover"
                        // height:{xl:845,lg:600,md:500,xs:247},
                        // borderRadius:{md:0,xs:"10px"}
                    }}
                >
                </Box>
                    <Box
                        sx={{
                            position:{md:"absolute",xs:"initial"},
                            padding:{xl:"50px 60px",md:"32px 40px",xs:"20px 0px 0px 0px"},
                            bgcolor:{md:"#F7F9FF",xs:"transparent"},
                            bottom:"0",
                            right:"0",
                            width:{md:"80%",xs:"100%"},
                            boxShadow:{md:"0px 2px 10px #0000001F",xs:"none"},
                        }}
                    >
                        <Stack spacing={{xl:2,xs:2}}>
                            <Typography variant="landingTitle">All major investment assets are available in digital form. Why not Real Estate?</Typography>
                            <Typography  variant="landingSubText">RealX brings Real Estate investing on par with other digital investments like stocks and commodities. Our investment process is completely digital. Just choose where to invest and let the benefits accrue to you with appointed professionals managing every aspect of property and funds administration.</Typography>
                            {/* <IsPossibleNow sx={{pt:{xl:0,xs:1}}} /> */}
                            <LearnMore link={"/why-realx"} sx={{pt:{xs:0.5,md:1}}} title={"Why RealX ?"} />
                        </Stack>

                    </Box>
            </Box>
        </Box>
    )
}

const SecondSection = () =>{
    return (
        <Box
            sx={{
                pb:{lg:"100px",md:"100px",xs:10},
                px:{lg:0,md:8,xs:3}
            }}
        >
            <Helmet>
                <meta name="description" content="we provide a platform for hassle-free investing in houses and properties. Discover the best places to invest in real estate and make the most of fractional investment opportunities. Your journey towards the best property investment starts with RealX." />
            </Helmet>

            <Box
                sx={{
                    width:{xl:1412,lg:1080},
                    mx:"auto"
                }}
            >
                <Grid container spacing={{xl:17,md:10,xs:5}} px={{md:10,xs:0}} alignItems={"center"} >
                    <Grid item xs={12} md={5}>
                        <Box
                            sx={{
                                width:"100%",
                                position:"relative",
                                pb:"100%"
                            }}
                        >
                            <Box sx={{width:"90%",pb:"90%",position:"absolute",top:0,left:0,bgcolor:"#283f54",boxShadow:"0px 3px 6px #00000029",}} />
                            <Box sx={{width:"90%",pb:"90%",position:"absolute",top:"10%",left:"10%",
                                boxShadow:"0px 3px 6px #00000029",
                                background:"url('/assets/images/landing/section3.png') no-repeat",backgroundSize:"cover"}} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Stack px={{md:0,xs:0}}  spacing={{md:3,xs:2.5}}>
                            <Stack spacing={{md:2,xs:2}}>
                                <Typography variant="landingTitle">Move over baseless Cryptos. Invest in Digital Asset Tokens linked with ownership of real assets.</Typography>
                                <Typography variant="landingSubText">
                                We embraced the new asset class based on BlockChain. However, most of these were flawed or baseless Cryptos and let us down. Move over baseless Cryptos. Buy Real Estate Digital Assets that can give you real ownership of properties and real benefits from them - real assets, real ownership, real gains, bought and sold with real money.
                                </Typography>
                            </Stack>
                            {/* <IsPossibleNow /> */}
                            <Stack direction={"row"} spacing={{md:5,xs:2}} >
                                <LearnMore link={"/how-it-works"} option={{tab:1}} title={"Learn about Ownership Modes & Property Tokens"} />
                                {/* <LearnMore title={"What are property Tokens?"} /> */}
                            </Stack>
                        </Stack>
                    </Grid>
                    
                </Grid>
            </Box>
        </Box>
    )
}

const ThirdSection = () =>{
    return(
        <Box
        sx={{
            pb:{lg:"150px",md:"150px",xs:10},
            px:{lg:0,xs:3,md:8}
        }}
        >
            <Helmet>
                <meta name="description" content="RealX enables the monetisation of real estate holdings by evaluating properties for potential investment from the community of investors. send us some details at property@realx.in to get your property registered." />
            </Helmet>

            <Box
                sx={{
                    pt:{md:5,xs:0},
                    width:{xl:1412,lg:1080},
                    mx:"auto"
                }}
            >
                <Stack direction={{md:"column",xs:"column-reverse"}} px={{md:10,xs:2}} spacing={{md:5,xs:5}}>
                    <Stack px={{md:10,xs:0}} spacing={{md:5,xs:2.5}}>
                    <Box 
                        sx={{
                            position:"relative",
                            width:"fit-content"
                            // width:{lg:"50%",md:"62%",xs:"100%"},
                        }}
                    >
                        <Box   
                            sx={{
                                width:"25%",
                                height:"60%",
                                bgcolor:"primary.main",
                                mask:`url('/assets/images/landing/arrow.svg')  no-repeat center / contain`,
                                position:"absolute",
                                right:"0%",
                                bottom:"-12%",
                                zIndex:1
                            }}
                        />
                        <Box
                            sx={{
                                width:"24%",
                                pb:"24%",
                                borderRadius:"100%",
                                position:"absolute",
                                bgcolor:"#FFF",
                                boxShadow:{md:"0px 0px 99px #00000021",xs:"0px 0px 30px #00000014"},
                                top:"-34%",
                                left:"-12%",
                                zIndex:1
                            }}
                        />
                        <Typography sx={{zIndex:2,position:"relative"}} variant="landingTitle">Real Estate has been the best<br /> performing asset class.</Typography>
                    </Box>
                    <Box width={{md:"100%",xs:"100%"}}>
                        <Typography variant="landingSubText">High quality properties are more expensive too and beyond reach for most of us. But together we can. Nothing is too expensive when we come together to buy. Opening opportunities to invest digitally in high quality properties beginning as low as INR 5000. Now, everyone can participate to become a co-owner in a high value property asset. And this is just the beginning. We promise to continue to strive hard to do more, much more.</Typography>
                        {/* <Typography variant="landingSubText"></Typography> */}
                    </Box>
                    </Stack>
                    <Box
                        // component={"img"}
                        // src={"/assets/images/landing/section2.png"}
                        sx={{
                            width:"100%",
                            boxShadow:"0px 0px 20px #0000000F",
                            background:{md:"url('/assets/images/landing/section2.png')",xs:"url('/assets/images/landing/section2-m.png')"},
                            backgroundSize:{md:"cover",xs:"cover"},
                            pt:{md:"25%",xs:"70%"},
                        }}
                    >
                        
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}


const ForthSection = () =>{
    return(
        <Box
            sx={{
                width:"100vw",
                left:"50%",
                right:"50%",
                marginX:"-50vw",
                position:"relative",
                bgcolor:"#FFF",
            }}
        >
            <Helmet>
                <meta name="description" content="RealX revolutionizes real estate monetization, making it accessible to all. Share property details at property.realx.in to attract investments from our community investors. We'll evaluate the submissions, and if it meets our criteria, we'll reach back to you. Embrace the possibilities with RealX!" />
                <meta name="keywords" content="tsPossibleNow"></meta>
            </Helmet>
            
            <Box
                sx={{
                    
                    pb:{lg:"70px",md:"70px",xs:5},
                    px:{lg:0,xs:3,md:8},
                    position:"relative",
                    zIndex:1,
                    maxWidth:"1920px",
                    margin:"auto"
                }}
            >
                <Box
                    sx={{
                        position:"relative",
                        mx:"auto",
                        width:{lg:"70%",xs:"100%"},
                        pt:{lg:"33%",xs:"70%"}
                    }}
                >
                    <Box 
                        sx={{
                            position:"absolute",
                            background:"url('/assets/images/landing/section4.png') no-repeat",
                            backgroundSize:"cover",
                            width:{md:"85%",xs:"100%"},
                            pt:{md:"40%",xs:"70%"},
                            top:0,
                            right:0,
                            // height:{xl:845,lg:600,md:500,xs:247},
                            // borderRadius:{md:0,xs:"10px"}
                        }}
                    >
                    </Box>
                    <Helmet>
                        <meta name="description" content="RealX empowers all to monetize real estate holdings. Submit property details at property@realx.in for evaluation by our community investors" />
                        <meta name="keywords" content="tsPossibleNow!"></meta>
                    </Helmet>
                        <Box
                            sx={{
                                position:{md:"absolute",xs:"initial"},
                                padding:{xl:"50px 60px",md:"32px 40px",xs:"20px 0px 0px 0px"},
                                bgcolor:{md:"#F7F9FF",xs:"transparent"},
                                bottom:"0",
                                left:"0",
                                width:{md:"80%",xs:"100%"},
                                boxShadow:{md:"0px 2px 10px #0000001F",xs:"none"},
                            }}
                        >
                            <Stack spacing={{xl:2.5,xs:2}}>
                                <Typography variant="landingTitle">Sell your property to a large community of investors.</Typography>
                                <Typography variant="landingSubText">
                                RealX opens doors for everyone to monetize their Real Estate holdings. If you have a property that can attract investment interest from our community investors, send us some details 
                                at <a href="mailto:property@realx.in" style={{textDecoration:"none"}}>property@realx.in</a> and we would be happy to evaluate and get back should it meets our criteria.
                                        </Typography>
                                <IsPossibleNow sx={{pt:{xl:0,xs:1}}} />
                                {/* <LearnMore sx={{pt:{xs:0.5,md:0.5}}} title={"Why Realx ?"} /> */}
                            </Stack>

                        </Box>
                </Box>
            </Box>
        </Box>
    )
}

const FifthSection = () =>{
    return(

        <Box
            sx={{
                bgcolor:"#FFF",
                position:"relative",
                zIndex:1,
                width:"100vw",
                left:"50%",
                right:"50%",
                marginX:"-50vw",
            }}
        >
            <Helmet>
                <meta name="description" content="Empowering Real Estate Investment with Blockchain for Digital India and Financial Inclusion. Governments worldwide endorse blockchain innovations that offer tangible advantages to people. Discover how RealX aligns with India's vision for progress. Explore government perspectives on blockchain applications."/>
                <meta name="keywords" content="RealEstateBlockchain, DigitalIndia, FinancialInclusion"></meta>
            </Helmet>

            <Box
                sx={{
                    background:"#2F6BF20A 0% 0% no-repeat padding-box",
                    py:{lg:"70px",xs:5},
                    px:{lg:8,md:8,xs:3}
                }}
            >
                <Box
                    sx={{
                        maxWidth:"1920px",
                        margin:"auto"
                    }}
                >
                    <Box
                        sx={{
                            width:{xl:"70%",md:"80%",xs:"100%"},
                            mx:"auto"
                        }}
                    >
                        <Grid container spacing={{md:17,xs:5}} px={{md:0,xs:0}} alignItems={"center"} >
                            <Grid item xs={12} md={5}>
                                <Box
                                    sx={{
                                        width:"100%",
                                        position:"relative",
                                        pb:"100%"
                                    }}
                                >
                                    <Box sx={{width:"90%",pb:"90%",position:"absolute",top:0,right:0,bgcolor:"#283f54",boxShadow:"0px 3px 6px #00000029",}} />
                                    <Box sx={{width:"90%",pb:"90%",position:"absolute",top:"10%",right:"10%",
                                        boxShadow:"0px 3px 6px #00000029",
                                        background:"url('/assets/images/landing/section5.png') no-repeat",backgroundSize:"cover"}} />
                                </Box>
                                <Helmet>
                                    <meta name="description" content="RealX: Empowering Real Estate Investment with Blockchain for Digital India. Governments endorse blockchain innovations with tangible benefits. Explore government perspectives on blockchain applications." />
                                    <meta name="keywords" content="RealEstateBlockchain, DigitalIndia"></meta>
                                </Helmet>

                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Stack px={{md:0,xs:2}} spacing={{md:3,xs:2.5}}>
                                    <Stack spacing={{md:2,xs:2}}>
                                        <Typography variant="landingTitle">Digital & Transparent Real Estate Market - aligned with the vision of Digital India</Typography>
                                        <Typography variant="landingSubText">
                                        The Government in India and governments and regulators around the world have been encouraging any innovation using BlockChain that is not speculative and brings real benefits to people. RealX is built with a vision to enhance the Digital India and Financial Inclusion goals of the government.
                                        </Typography>
                                    </Stack>
                                    {/* <IsPossibleNow /> */}
                                    <LearnMore link={"/faq"} option={{tab:1}} title={"See what the government has been saying on Blockchain uses"} />
                                </Stack>
                            </Grid>
                            
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const SixthSection = () =>{
    return(
        <Box
            sx={{
                bgcolor:"#FFF",
                position:"relative",
                zIndex:1,
                width:"100vw",
                left:"50%",
                right:"50%",
                marginX:"-50vw",
            }}
        >
            <Helmet>
                <meta name="description" content="Invest Confidently with RealX, the Leading Innovator. As pioneers in tokenizing real estate, we offer direct ownership claims on property assets. Our robust framework ensures investor protection, adhering to best financial institution practices. From top-notch deal curation to prioritizing governance and data privacy on PropChain, we work diligently behind the scenes. Trust RealX for secure, long-term investments. #InvestWithConfidence." />
                <meta name="keywords" content="InvestWithConfidence, RealEstateTokenization"></meta>
            </Helmet>

            <Box
                sx={{
                    bgcolor:"#FFF",
                    position:"relative",
                    maxWidth:"1920px",
                    margin:"auto",
                    zIndex:1,
                    py:{lg:"70px",md:"70px",xs:5},
                    px:{lg:0,md:8,xs:3}
                }}
            >
                <Box
                    sx={{
                        width:{xl:1412,lg:1080},
                        mx:"auto"
                    }}
                >
                    <Helmet>
                        <meta name="description" content="Invest with confidence in RealX, the pioneering leader in real estate tokenization. Our robust framework ensures direct ownership claims, top-notch deal curation, and utmost investor protection. Trust us for secure, long-term investments." />
                        <meta name="keywords" content="InvestWithConfidence, RealEstateTokenization"></meta>
                    </Helmet>

                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                            <Typography variant="landingTitle">
                                Invest with confidence. Invest with the leader.
                            </Typography>
                            {/* <LearnMore sx={{display:{md:"flex",xs:"none"}}} /> */}
                        </Stack>
                        <Grid container columnSpacing={10} rowSpacing={2.5} mt={{md:0.5,xs:0}}>
                            <Grid item md={6} xs={12}>
                                <Typography variant="landingSubText">
                                    RealX is a pioneer in the world to have created a framework, legal and technical to issue tokens that have direct ownership claim on the property asset. RealX was built incorporating the best principals and prudential norms followed by financial institutions.
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="landingSubText">
                                    Investor protection is at the core of the processes framework at RealX and it will continue to be in everything we do – from curating the best deals to implementing governance at PropChain to the privacy of your data. All this is invisible work we do and will keep doing on priority, with a long term view, so you can invest with confidence.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <IsPossibleNow sx={{mt:{md:5,xs:3}}} /> */}
                        <LearnMore link={"/why-realx"} sx={{mt:{md:5,xs:3}}} title={"Why RealX?"} />
                </Box>

            </Box>
        </Box>
    )
}

// const WhySection = () =>{

//     const data =[
//         {
//             title:"Direct Co-Ownership",
//             description:"Direct registered co-ownership in the property – What you see is what you get",
//             img:"/assets/images/landing/1.png"
//         },
//         {
//             title:"Investor Protection",
//             description:"Properties are registered in the names of the investors",
//             img:"/assets/images/landing/2.png"
//         },
//         {
//             title:"Tax Efficient",
//             description:"RealX is at least 25% more Tax Efficient than its peers",
//             img:"/assets/images/landing/3.png"
//         },
//         {
//             title:"Completely Digital Process",
//             description:"Hassle free entry, benefits management (passive) and exit",
//             img:"/assets/images/landing/4.png"
//         },
//     ]

//     return(
//         <Box sx={{
//             // width:"100vw",
//             // height:"100vh",
//             py:"100px"
//         }}>
//             <Typography sx={{fontWeight:700,fontSize:{lg:60},textAlign:"center"}}>WHY REALX?</Typography>
//             <Typography sx={{fontSize:16,textAlign:"center",pt:2.5}}>Besides being one of the most innovative FinTech company in its space globally,<br/> RealX has investor first approach at its core</Typography>
//             <Stack direction={"row"} justifyContent={"center"} spacing={"60px"} mt={10} >
//                 {
//                     data.map((e)=>{
//                         return(
//                             <Stack direction={"column"} alignItems={"center"}>
//                                 <Box sx={{
//                                     width:110,
//                                     height:110,
//                                     borderRadius:"100%",
//                                     bgcolor:"primary.light",
//                                     padding:3
//                                 }}>
//                                     <Box component={"img"} src={e.img} width={"100%"} />
//                                 </Box>
//                                 <Typography sx={{fontSize:18,fontWeight:700,pt:2.5}}>{e.title}</Typography>
//                                 <Box sx={{width:256,textAlign:"center",}}>
//                                     <Typography variant="body2" sx={{fontSize:15,textAlign:"center",pt:2.5,lineHeight:2}}>{e.description}</Typography>
//                                 </Box>
//                             </Stack>
//                         )
//                     })
//                 }
//             </Stack>
//             <Stack mt={15} >
//                 <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={"200px"}>
//                     <Box
//                         component={"img"}
//                         src={"/assets/images/landing/building.png"}
//                     >

//                     </Box>
//                     <Stack>
//                         <Typography sx={{fontSize:18,fontWeight:700,opacity:0.5}}>With RealX, you get access to</Typography>
//                         <Typography sx={{fontSize:{lg:48},fontWeight:700,mt:4}}>Multiple Asset Class</Typography>
//                         <Stack spacing={2} mt={7}>
//                             <Stack direction={"row"} alignItems={"center"} spacing={1.2}>
//                                     <Box sx={{minWidth:{xs:20,md:20},height:{xs:20,md:20},bgcolor:"primary.main",borderRadius:"100%",display:"flex",
//                                     justifyContent:"center",
//                                     alignItems:"center",}} >
//                                         <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:10,md:10},height:{xs:10,md:10},}} />
//                                     </Box>
//                                 <Typography sx={{fontSize:20,fontWeight:500}}>Zero Compliance</Typography>
//                             </Stack>
//                             <Stack direction={"row"} alignItems={"center"} spacing={1.2}>
//                                 <Box sx={{minWidth:{xs:20,md:20},height:{xs:20,md:20},bgcolor:"primary.main",borderRadius:"100%",display:"flex",
//                                     justifyContent:"center",
//                                     alignItems:"center",}} >
//                                         <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:10,md:10},height:{xs:10,md:10},}} />
//                                     </Box>
//                                 <Typography sx={{fontSize:20,fontWeight:500}}>Full Transparency</Typography>
//                             </Stack>
//                             <Stack direction={"row"} alignItems={"center"} spacing={1.2}>
//                                 <Box sx={{minWidth:{xs:20,md:20},height:{xs:20,md:20},bgcolor:"primary.main",borderRadius:"100%",display:"flex",
//                                     justifyContent:"center",
//                                     alignItems:"center",}} >
//                                         <CustomIcon name="check" sx={{bgcolor:"#FFF",width:{xs:10,md:10},height:{xs:10,md:10},}} />
//                                     </Box>
//                                 <Typography sx={{fontSize:20,fontWeight:500}}>Professional Diligence</Typography>
//                             </Stack>
//                         </Stack>
//                         <Stack direction={"row"} spacing={1.2} mt={4} color={"primary.main"} alignItems={"center"}>
//                             <Typography sx={{fontSize:16,fontWeight:700}}>Learn more</Typography>
//                             <KeyboardArrowRightIcon fontSize="small" />
//                         </Stack>
//                     </Stack>
//                 </Stack>
//             </Stack>
//         </Box>
//     )
// }

// const ContactUsSection = () =>{
//     return(
//         <Box sx={{
//             width:"100vw",
//             height:"100vh",
//             px:"120px",
//             display:"flex",
//             flexDirection:"column",
//             justifyContent:"center",
//             alignItems:"center"
//         }}>
//             <Typography sx={{fontWeight:700,fontSize:{lg:60},textAlign:"center"}}>Contact us</Typography>
//             <Typography sx={{fontSize:16,textAlign:"center",pt:2.5}}>For any question please send us email our contact team will get back to you within 24 hours</Typography>
//                 <a href="mailto:hi@realx.in" style={{textDecoration:"none"}}>
//                     <Typography sx={{fontSize:{lg:48},fontWeight:700,mt:4,color:"text.primary"}}>hi@realx.in</Typography>
//                 </a>
//         </Box>
//     )
// }


const LearnMore = ({sx={},title="Learn More",link,option}) =>{
    const navigate = useNavigate();

    const handleLink = () =>{
        if(link){
            navigate(link,{state:option});
        }
    }

    return(
        <Stack direction={"row"} alignItems={"center"} spacing={{md:3,xs:2}} sx={{cursor:"pointer",...sx}}>
            <Typography onClick={handleLink} variant="landingLink" color={"primary.main"}>{title}</Typography>
            {/* <Box sx={{
                width:{lg:24,xs:12},
                height:{lg:24,xs:12},
                borderRadius:"100%",
                bgcolor:"primary.main",
                boxShadow:"0px 6px 20px #2F6BF266",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <KeyboardArrowRightIcon sx={{color:"#FFF",fontSize:{xs:12,lg:24}}} />
            </Box> */}
        </Stack>
    )
}

const IsPossibleNow = ({sx={}}) =>{
    return(
        <Typography sx={{mt:'5px !important'}} variant="h5">#ItsPossibleNow</Typography>
    )
}
